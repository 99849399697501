import React from 'react';
import { Box } from '@latitude/box';
import { Metadata } from '@latitude/metadata';
import { SecondaryHero } from '@latitude/hero';
import {
  BREAKPOINT,
  COLOR,
  MARGIN,
  PADDING
} from '@latitude/core/utils/constants';
import Layout from '../components/layout';

const PartnerDiscount = ({ location }) => (
  <Layout location={location}>
    <main className="navigation-spacer">
      <Metadata
        title="Partner Discount | Gem by Latitude"
        description=""
        canonical={`${location.href}`}
        noIndex
        noFollow
      />
      <SecondaryHero heading="Partner Discount" />
      <Box
        backgroundColor={COLOR.GREY6}
        css={`
          padding: ${PADDING.P16} 0;
          @media (min-width: ${BREAKPOINT.SM}) {
            padding: ${PADDING.P48} ${PADDING.P16};
          }
        `}
      >
        <Box isResponsive margin={`0 ${MARGIN.MAUTO}`}>
          <iframe
            css={`
              width: 100%;
              height: 1380px;
              padding: ${PADDING.P16};
              background-color: ${COLOR.WHITE};
              border: solid 1px ${COLOR.GREY16};

              .partner-discount .prompt {
                border: 10px solid red;
              }

              @media (min-width: ${BREAKPOINT.MD}) {
                padding: ${PADDING.P24};
                height: 1360px;
              }
              @media (min-width: ${BREAKPOINT.LG}) {
                height: 1340px;
              }
              @media (min-width: ${BREAKPOINT.XL}) {
                height: 1110px;
              }
            `}
            title="gemvisanz-annual-fee-waiver-form"
            className="partner-discount"
            src="https://eform.latitudefinancial.com.au/eform/Page.do?eFormName=gemvisanz_annual_fee_waiver_form"
          />
        </Box>
      </Box>
    </main>
  </Layout>
);

export default PartnerDiscount;
